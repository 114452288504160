var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.forDialog === false
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    !_vm.iconShaped
                      ? _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "font-weight-bold mr-1 mt-1 caption",
                              attrs: {
                                depressed: "",
                                small: "",
                                loading: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitQuery(_vm.mode, _vm.query)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "primary", small: "" }
                                  },
                                  [_vm._v("play_arrow")]
                                ),
                                _c("span", { staticClass: "primary--text" }, [
                                  _vm._v(_vm._s(_vm.buttonName))
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      : _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "font-weight-bold mr-1",
                              attrs: {
                                icon: "",
                                loading: _vm.loading,
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitQuery(_vm.mode, _vm.query)
                                }
                              }
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-refresh")])],
                          1
                        )
                  ]
                }
              }
            ],
            null,
            false,
            3242743741
          )
        },
        [_c("span", [_vm._v(_vm._s(_vm.tooltipHint))])]
      )
    : _c(
        "v-btn",
        {
          attrs: { color: "secondary" },
          on: {
            click: function($event) {
              return _vm.submitQuery(_vm.mode, _vm.query)
            }
          }
        },
        [_vm._v(_vm._s(_vm.buttonName))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }