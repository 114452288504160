<template>
    <v-tooltip v-if="forDialog === false" bottom>
        <template v-slot:activator="{ on }">
            <v-btn @click="submitQuery(mode, query)" class="font-weight-bold mr-1 mt-1 caption" depressed small v-on="on" :loading="loading" v-if="!iconShaped">
                <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-1" small>play_arrow</v-icon>
                    <span class="primary--text">{{ buttonName }}</span>
                </div>
            </v-btn>
            <v-btn @click="submitQuery(mode, query)" class="font-weight-bold mr-1" v-else icon :loading="loading" v-on="on" color="primary">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </template>
        <span>{{ tooltipHint }}</span>
    </v-tooltip>
    <v-btn v-else color="secondary" @click="submitQuery(mode, query)">{{ buttonName }}</v-btn>
</template>
<script>
export default {
    name: 'QuerySubmit',
    props: {
        mode: String,
        query: String,
        tooltipHint: String,
        iconShaped: Boolean,
        consoleSql: String,
        forDialog: Boolean,
        buttonName: String
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        submitQuery: function(mode, query) {
            if (this.$props.forDialog === true) {
                this.$emit('closeDialog', { value: true })
            }
            this.$data.loading = true
            var sql
            if (query) {
                sql = query
            } else if (this.$props.consoleSql) {
                sql = this.$props.consoleSql
            }
            if (sql) {
                const postBody = {
                    command: sql.trim(),
                    mode: this.$props.mode,
                    snid: this.$route.params.snid
                }
                this.$emit('success', { value: true })
                this.$axios
                    .post('/queries', postBody)
                    .then(response => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Query has been submitted',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        })
                        this.$store.dispatch('snapshotStore/fetchQueries', this.$route.params.snid)
                    })
                    .catch(() => {
                        this.$store.dispatch('snapshotStore/fetchQueries', this.$route.params.snid)
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to submit the query, please retry later..',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.$data.loading = false
                    })
            }
        }
    }
}
</script>
